<template>
	<div class="tournament">
		<SubHeader title="ODO Tournament" subtitle="ODOトーナメント" />

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);"> 2023年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/tournament/2022"> 2022年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/tournament/2021"> 2021年度 </a>
				</li>
			</ul>

			<div class="row justify-content-center">
				<div class="col-lg-12 mb-4">
					<h2>ODO Darts Tournament 2023</h2>
					<img src="/images/tournament/2023/tournament.jpg" class="img-fluid" />
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>メンズ&nbsp;シングルス</h4>
				</div>
				<div class="col-lg-3 col-6 text-center offset-lg-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/tournament/2023/result/mens_singles_2nd.jpg"
							class="img-fluid"
							@click="openGallery(3)"
						/>
						<span class="name">外筬 久人（大阪）</span>
					</p>
				</div>
				<div class="col-lg-4 col-6 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/tournament/2023/result/mens_singles_winner.jpg"
							class="img-fluid"
							@click="openGallery(2)"
						/>
						<span class="name">磯和 勇希（大阪）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2023/result/mens_singles_3rd.jpg"
							class="img-fluid"
							@click="openGallery(4)"
						/>
						<span class="name">月森 和也（愛知）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2023/result/mens_singles_4th.jpg"
							class="img-fluid"
							@click="openGallery(5)"
						/>
						<span class="name">嶋内 優樹（高知）</span>
					</p>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>レディース&nbsp;シングルス</h4>
				</div>
				<div class="col-lg-3 col-6 text-center offset-lg-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/tournament/2023/result/ladies_singles_2nd.jpg"
							class="img-fluid"
							@click="openGallery(7)"
						/>
						<span class="name">西田 睦（奈良）</span>
					</p>
				</div>
				<div class="col-lg-4 col-6 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/tournament/2023/result/ladies_singles_winner.jpg"
							class="img-fluid"
							@click="openGallery(6)"
						/>
						<span class="name">西口 小百合（兵庫）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2023/result/ladies_singles_3rd.jpg"
							class="img-fluid"
							@click="openGallery(8)"
						/>
						<span class="name">青木 まゆ（大阪）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2023/result/ladies_singles_4th.jpg"
							class="img-fluid"
							@click="openGallery(9)"
						/>
						<span class="name">田中 ニコル（神奈川）</span>
					</p>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>オープン&nbsp;ダブルス</h4>
				</div>
				<div class="col-lg-3 col-6 text-center offset-lg-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/tournament/2023/result/open_doubles_2nd.jpg"
							class="img-fluid"
							@click="openGallery(11)"
						/>
						<span class="name">柳瀬 将一（大阪）・芳村 幹陽（大阪）</span>
						<br /><br />
					</p>
				</div>
				<div class="col-lg-4 col-6 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/tournament/2023/result/open_doubles_winner.jpg"
							class="img-fluid"
							@click="openGallery(10)"
						/>
						<span class="name">時長 敬介（神奈川）・内藤 光希（神奈川）</span
						><br /><br />
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2023/result/open_doubles_3rd.jpg"
							class="img-fluid"
							@click="openGallery(12)"
						/>
						<span class="name">松並 浩二（一般）・杉山 祐貴（一般）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2023/result/open_doubles_4th.jpg"
							class="img-fluid"
							@click="openGallery(12)"
						/>
						<span class="name">東村 卓使（高知）・嶋内 優樹（高知）</span>
					</p>
				</div>
			</div>

			<div class="row mb-4">
				<div class="col-lg-8 col-12 mb-3">
					<h5 class="mb-4">開催情報</h5>
					<dl class="row">
						<dt class="col-sm-2">日時</dt>
						<dd class="col-sm-10">
							<span style="font-size: 1.2rem">2023年10月8日（日）</span>
							<br />
							チェックイン：9:15〜、開会式：10:00〜、競技開始：10:15〜<br />
							※受付時はユニフォームを着用し競技に参加できる容姿にてお願いします。
						</dd>
						<dt class="col-sm-2">場所</dt>
						<dd class="col-sm-10">
							<span style="font-size: 1rem"
								>公益法人 堺市産業振興センター イベントホール</span
							><br />
							〒591-8025 大阪府堺市北区長曽根町183-5<br />
							<a href="https://www.sakai-ipc.jp/" target="_blank"
								>https://www.sakai-ipc.jp/</a
							>
						</dd>
						<dt class="col-sm-2">申込締切</dt>
						<dd class="col-sm-10">2023年9月30日（土）</dd>
						<dt class="col-sm-2">主催</dt>
						<dd class="col-sm-10">大阪ダーツオーガニゼイション</dd>
						<dt class="col-sm-2">協力</dt>
						<dd class="col-sm-10">
							茨木ダーツクラブ、東大阪ダーツクラブ、Japan Darts
							Organizations、JDO各DO
						</dd>
						<dt class="col-sm-2">協賛</dt>
						<dd class="col-sm-10">
							G.S.D、nakka.com、千雅、有限会社FarEast、スキマ産業
						</dd>
						<dt class="col-sm-2">協力店</dt>
						<dd class="col-sm-10">
							SELFISH、Hugglife、DUETT、BULL、Harrow's、LEAFLET、BALABUSHKA、わんすもあー、
							Darts Bar JADE、Darts Bar Famous、 現場ar、Bar
							Eau-de-Vie、うまいや宗右衛門町ハイボール、max
							OSAKA・心斎橋、Public Bar Stinger、Darts House Reve、KITCHEN ＆
							BAR O2、Dining BAR Glow、DARTS BAR ひだまり、HIDE OUT、お好み焼き
							だん、Far East Darts
						</dd>
						<dt class="col-lg-2"></dt>
						<dd class="col-lg-10">
							<a
								class="btn btn-download mr-3"
								href="/pdf/2023/odo_tournament_2023.pdf"
								target="_blank"
								><i class="far fa-file-pdf mr-2"></i> 開催要項 PDF</a
							>
							<a
								class="btn btn-download"
								href="http://japan-darts.com/rule/"
								target="_blank"
								><i class="fas fa-file-excel mr-2"></i>
								エントリーシート（JDOサイト）</a
							><br />
							<a
								class="btn btn-download"
								href="https://drive.google.com/drive/folders/1EZQPddx6Q50Us_Y2OQ4s6uKJMeO3URby"
								target="_blank"
								><i class="fas fa-video mr-2"></i>
								n01アプリ操作ガイド・マーカー解説動画（mp4）</a
							>
						</dd>
					</dl>
				</div>
				<div class="col-lg-4 col-12 mb-3">
					<p>
						<img
							src="/images/tournament/2023/poster.jpg"
							class="img-fluid"
							@click="openGallery(0)"
						/>
					</p>
					<p>
						<img
							src="/images/tournament/2023/uniform.jpg"
							class="img-fluid"
							@click="openGallery(1)"
						/>
					</p>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-8 col-12 mb-3">
					<h5 class="mb-2">エントリーリスト</h5>
					<div class="table-responsive mb-5">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">#</th>
									<th scope="col" class="text-nowrap">所属</th>
									<th scope="col" class="text-nowrap">会員番号</th>
									<th scope="col" class="text-nowrap">名前</th>
									<th scope="col" class="text-nowrap">フリガナ</th>
									<th scope="col" class="text-nowrap">性別</th>
									<th scope="col" class="text-nowrap">ペア</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(player, index) in entries">
									<tr v-if="player.member_id != ''" :key="'rank' + index">
										<td class="text-nowrap">{{ index + 1 }}</td>
										<td class="text-nowrap">{{ player.area }}</td>
										<td class="text-nowrap">{{ player.member_id }}</td>
										<td class="text-nowrap score">
											{{ player.player_name }}
										</td>
										<td class="text-nowrap score">
											{{ player.player_kana }}
										</td>
										<td class="text-nowrap score">{{ player.player_sex }}</td>
										<td class="text-nowrap score">{{ player.doubles_id }}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-lg-4 col-12 mb-3"></div>
			</div>

			<LightBox
				ref="lightbox"
				:media="media"
				:show-light-box="false"
				:show-caption="true"
			></LightBox>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//import VueLazyLoad from "vue-lazyload";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

Vue.use(Loading);

export default {
	name: "tournament2020",
	components: {
		SubHeader,
		LightBox,
	},
	data() {
		return {
			isLoading: true,
			entries: [],
			media: [
				{
					thumb: "/images/tournament/2023/poster.jpg",
					src: "/images/tournament/2023/poster.jpg",
					caption: "ポスター",
				},
				{
					thumb: "/images/tournament/2023/uniform.jpg",
					src: "/images/tournament/2023/uniform.jpg",
					caption: "ドレスコード",
				},
				{
					thumb: "/images/tournament/2023/result/mens_singles_winner.jpg",
					src: "/images/tournament/2023/result/mens_singles_winner.jpg",
					caption: "磯和 勇希（大阪）",
				},
				{
					thumb: "/images/tournament/2023/result/mens_singles_2nd.jpg",
					src: "/images/tournament/2023/result/mens_singles_2nd.jpg",
					caption: "外筬 久人（大阪）",
				},
				{
					thumb: "/images/tournament/2023/result/mens_singles_3rd.jpg",
					src: "/images/tournament/2023/result/mens_singles_3rd.jpg",
					caption: "月森 和也（愛知）",
				},
				{
					thumb: "/images/tournament/2023/result/mens_singles_4th.jpg",
					src: "/images/tournament/2023/result/mens_singles_4th.jpg",
					caption: "嶋内 優樹（高知）",
				},

				{
					thumb: "/images/tournament/2023/result/ladies_singles_winner.jpg",
					src: "/images/tournament/2023/result/ladies_singles_winner.jpg",
					caption: "西口 小百合（兵庫）",
				},
				{
					thumb: "/images/tournament/2023/result/ladies_singles_2nd.jpg",
					src: "/images/tournament/2023/result/ladies_singles_2nd.jpg",
					caption: "西田 睦（奈良）",
				},
				{
					thumb: "/images/tournament/2023/result/ladies_singles_3rd.jpg",
					src: "/images/tournament/2023/result/ladies_singles_3rd.jpg",
					caption: "青木 まゆ（大阪）",
				},
				{
					thumb: "/images/tournament/2023/result/ladies_singles_4th.jpg",
					src: "/images/tournament/2023/result/ladies_singles_4th.jpg",
					caption: "田中 ニコル（神奈川）",
				},

				{
					thumb: "/images/tournament/2023/result/open_doubles_winner.jpg",
					src: "/images/tournament/2023/result/open_doubles_winner.jpg",
					caption: "時長 敬介（神奈川）・内藤 光希（神奈川）",
				},
				{
					thumb: "/images/tournament/2023/result/open_doubles_2nd.jpg",
					src: "/images/tournament/2023/result/open_doubles_2nd.jpg",
					caption: "柳瀬 将一（大阪）・芳村 幹陽（大阪）",
				},
				{
					thumb: "/images/tournament/2023/result/open_doubles_3rd.jpg",
					src: "/images/tournament/2023/result/open_doubles_3rd.jpg",
					caption: "松並 浩二（一般）・杉山 祐貴（一般）",
				},
				{
					thumb: "/images/tournament/2023/result/open_doubles_4th.jpg",
					src: "/images/tournament/2023/result/open_doubles_4th.jpg",
					caption: "東村 卓使（高知）・嶋内 優樹（高知）",
				},
			],
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {};
			this.axios
				.get(
					"https://script.google.com/macros/s/AKfycbzh9P2nIOScBPtACiekBIyo6H6XlSs5xBvRfWBbqE3bEtOETy6ZSC71tMXjoe3jGTTY/exec",
					{
						params: params,
					}
				)
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.entries = res.data.list;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
		openGallery(index) {
			this.$refs.lightbox.showImage(index);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
a {
	text-decoration: underline;
}
.container {
	padding: 5rem 1rem;
	text-align: left;
}
.btn-download {
	font-size: 1rem;
	color: #333;
	border: none;
	margin-top: 1rem;
	background: $primary-color;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
